<!--
    Created by 王丽莎 on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：设备管理 - 新增出厂设备
    功能概述：新增出厂设备
-->
<style lang="less">
.add-device-submit-content {
  padding-bottom: @containerGap;
  .ivu-form {
    .ivu-form-item {
      display: block;
      .ivu-form-item-label {
        margin-bottom: 5px;
        text-align: left;
        display: block;
        float: none;
      }
      .ivu-form-item-content {
        display: block;
        min-width: 100%;
      }
    }
  }
  .upload-box-outer {
    width: 100%;
  }
}
</style>

<template>
  <yg-modal
    width="460"
    title="新增出厂设备"
    sure-text="保存"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 200px"
    >
      <div class="add-device-submit-content">
        <Form>
          <FormItem
            v-for="(item, index) in formItems"
            :key="index"
            :label="item.label"
            :required="item.isRequired"
          >
            <!--生产厂商-->
            <yg-factory-type-cascader
              v-if="item.tagName === 'YgFactoryTypeCascader'"
              v-model="model[item.key]"
              :level="3"
            />
            <div
              v-if="item.tagName === 'Upload'"
              v-permission="'system:device:factory:upload'"
              class="upload-box-outer"
            >
              <yg-choose-excel
                template-url="/opmp/device/downloadConfigTemplate"
                template-name="出厂设备模版"
                @on-change="onChooseExcel"
              />
            </div>
          </FormItem>
        </Form>
      </div>
    </div>
  </yg-modal>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      // 表单数据
      formItems: [
        {
          label: '设备商/厂商/设备型号',
          key: 'h5FactoryTypeArr',
          tagName: 'YgFactoryTypeCascader',
          disabled: false,
          clearable: true,
          isRequired: true
        },
        {
          label: '添加文件',
          key: 'file',
          tagName: 'Upload',
          isRequired: true
        }
      ]
    };
  },
  methods: {
    onSure () {
      const res = this.model.checkValidSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        // 请求导入
        this.requestUpload(res.params);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    // 选择excel
    onChooseExcel (file) {
      this.model.file = file;
      this.checkSubmit();
    },
    // 检查必填项
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    },
    // 请求导入
    requestUpload (params) {
      const api = DeviceApiSet.uploadFactoryRecord;
      const formDt = new FormData();
      formDt.append('file', params.file);
      formDt.append('deviceTypeId', params.deviceTypeId);
      formDt.append('factoryType', params.factoryType);
      formDt.append('deviceProviderId', params.deviceProviderId);
      api.params = formDt;
      this.$httpFormData(api).then(res => {
        this.$Message.success(res.data);
        this.$emit('on-sure');
      }).catch(e => {
        this.clearImportFile();
      });
    }
  }
};
</script>
